/* eslint-disable no-console */
import { mul, sub, add, div } from 'exact-math';
import {
  createStore,
  useStore,
  SelectorFunction,
  getStorageValue,
  setStorageValue,
  isString,
  isNumber,
  isPlainObject,
} from '@veraio/core';
import { isBoolean } from 'lodash-es';
import { getLocationsState } from './location';
import {
  promoCodeType,
  DEFAULT_FIAT_CURRENCY,
  CRYPTO_CURRENCIES,
  DEFAULT_CRYPTO_CURRENCY,
  DEFAULT_ONE_RATE,
} from '../enums';
import { config, isConfigured } from '../config';
import { getCoinExchangeRate, getCurrenciesExchangeRates } from '../services';
import {
  roundCrypto,
  roundFiat,
  calculateDealPriceWithPromoCode,
  calculateDealPrice,
  stringifyToFirstNonZeroDigit,
} from '../utils';
import {
  CurrenciesStore,
  Currency,
  ConvertibleCurrencyReturn,
  ConvertibleCurrency,
  ShoppingCart,
  FiatLabel,
  CurrencyPrint,
  ShoppingCartCheckAmount,
} from '../interfaces';

const CURRENCY_KEY = 'currency';

const defaultValue: CurrenciesStore = {
  selectedCurrency: null,
  allCurrencies: null,
  fiatCurrencies: null,
  cryptoCurrencies: null,
  displayFiatOnly: true,
};

const currenciesStore = createStore(defaultValue);

/**
 * Initialize currencies store with this method. It will perform requests to get currency convert rates
 *
 * @example
 *
 * initCurrenciesStore();
 */
export const initCurrenciesStore = async (): Promise<void> => {
  if (!isConfigured('initCurrenciesStore')) return;

  const responses = await Promise.all([getCoinExchangeRate(), getCurrenciesExchangeRates()]);
  const [[oneRateResponse], [currencyRatesResponse]] = responses;
  const oneRate = Number(oneRateResponse?.value ?? DEFAULT_ONE_RATE);
  const allCurrencies = [{ ...DEFAULT_CRYPTO_CURRENCY, rate: oneRate }, ...(currencyRatesResponse ?? [])];
  const geoLocation = getLocationsState()?.geoLocation;
  const defaultCurrencyCode =
    getStorageValue<string>(CURRENCY_KEY, config.storage) ?? geoLocation?.currencyCode ?? DEFAULT_FIAT_CURRENCY.code;
  currenciesStore.setState((prev) => ({
    ...prev,
    selectedCurrency:
      findFiatCurrency(defaultCurrencyCode ?? DEFAULT_FIAT_CURRENCY.code, currencyRatesResponse) ??
      DEFAULT_FIAT_CURRENCY,
    allCurrencies,
    fiatCurrencies: currencyRatesResponse,
    cryptoCurrencies: Object.values(CRYPTO_CURRENCIES).map((el) => ({
      ...el,
      rates: { [DEFAULT_FIAT_CURRENCY.code]: oneRate },
    })),
  }));
};

/**
 * Returns matched fiat currency which has the provided currency code
 *
 * @param currencyCode string to which it will match the currency code
 * @param currencies an array of currencies to look into, if not passed the fiatCurrencies from store will be used
 * @returns {Currency} object with matched currency
 * @example
 *
 * findFiatCurrency('EUR') // { code: 'EUR', name: 'Euro', rates: [], ... }
 * findFiatCurrency('BGN', fiatCurrencies) // { code: 'BGN', name: 'Bulgarian lev', rates: [], ... }
 */
export const findFiatCurrency = (
  currencyCode?: string | null,
  currencies?: Currency[] | null,
): Currency | undefined => {
  const { fiatCurrencies } = currenciesStore.getState();

  return (currencies ?? fiatCurrencies ?? []).find((el) => el.code === currencyCode);
};

/**
 * Returns matched crypto currency which has the provided currency code
 *
 * @param currencyCode string to which it will match the currency code
 * @param currencies an array of currencies to look into, if not passed the cryptoCurrencies from store will be used
 * @returns {Currency} object with matched currency
 * @example
 *
 * findCryptoCurrency('OESP') // { code: 'OESP', name: 'Oneecosystem Points', rates: [], ... }
 * findCryptoCurrency('ONE', cryptoCurrencies) // { code: 'ONE', name: 'ONE', rates: [], ... }
 */
export const findCryptoCurrency = (
  currencyCode?: string | null,
  currencies?: Currency[] | null,
): Currency | undefined => {
  const { cryptoCurrencies } = currenciesStore.getState();

  return (currencies ?? cryptoCurrencies ?? []).find((el) => el.code === currencyCode);
};

/**
 * Returns the fiat symbol of user selected currency
 *
 * @param fiatDesiredCurrency the currency to which code or symbol will be returned, if not passed the selectedCurrency label will be returned
 * @param fiatLabelSign the chosen label, it can return the code of the currency BGN, EUR and so on, or the symbol лв., Є and so on
 * @returns {string} the symbol or the code of provided or user selected fiat currency
 * @example
 *
 * fiatSign() // 'лв.'
 * fiatSign('EUR') // 'Є'
 * fiatSign('EUR', 'code') // 'EUR'
 * fiatSign(null, 'code') // 'EUR'
 */
export const fiatSign = (fiatDesiredCurrency?: string, fiatLabelSign: FiatLabel = 'symbol'): string => {
  const matchedCurrency =
    findFiatCurrency(fiatDesiredCurrency) ?? currenciesStore.getState()?.selectedCurrency ?? DEFAULT_FIAT_CURRENCY;

  return matchedCurrency[fiatLabelSign];
};

/**
 * Returns the crypto currency sign based on that if the user is from crypto restricted country
 *
 * @returns {string} the symbol of user selected crypto currency
 * @example
 *
 * cryptoSign() // 'ONE'
 * cryptoSign() // 'OESP'
 */
export const cryptoSign = (cryptoDesiredCurrency?: string): string =>
  findCryptoCurrency(cryptoDesiredCurrency)?.symbol ??
  (getLocationsState()?.geoLocation?.isCryptoRestricted ? CRYPTO_CURRENCIES.OESP.symbol : CRYPTO_CURRENCIES.ONE.symbol);

/**
 * Convert an amount of fiat to user selected currency, used mainly to convert deal price fiat price which is in EUR to user selected currency\
 * Convert an amount of fiat crypto to crypto, used mainly to convert deal price crypto price which is in EUR to crypto\
 * Convert an amount of crypto to fiat, used mainly to convert wallet ballance to fiat for checks of available credit\
 * Convert an amount of fiat user selected currency to base system currency, used mainly to convert price before sending it to API\
 * API send us prices in system currency, we need to send them back again is system currency
 *
 * @param fiat fiat not converted from fiat user selected currency - e.g. 40 BGN, 70 BGN
 * @param fiatOriginCurrency the currency of given fiat amount as fiat param, by default is EUR - e.g. BGN, EUR
 * @param fiatDesiredCurrency the currency to which fiat will be converted, by default is user selected currency - e.g. BGN, EUR
 * @param fiatCrypto fiatCrypto not converted from fiat to crypto - e.g. 42.5 EUR, 76 EUR
 * @param crypto crypto already converted from fiat to crypto - e.g. 4 ONE, 1.4567 ONE
 * @param cryptoOriginCurrency the currency of given fiatCrypto amount as fiatCrypto param, by default is ONE - e.g. ONE, OESP
 * @param cryptoDesiredCurrency the currency to which fiatCrypto will be converted, by default is ONE - e.g. ONE, OESP
 * @returns {number} converted user selected fiat to base fiat
 * @example
 *
 * convertCurrencies({ fiat: 20, fiatDesiredCurrency: 'BGN' }) // {fiat: 39.11, crypto: null, fiatCrypto: null} (20 EUR -> 39.11 BGN and user selected currency is BGN)
 * convertCurrencies({ fiat: 40, fiatOriginCurrency: 'BGN' }) // {fiat: 20.46, crypto: null, fiatCrypto: null} (40 BGN -> 20.46 EUR and user selected currency is EUR)
 * convertCurrencies({ crypto: 1.2345 }) // {fiat: null, crypto: 1.2345, fiatCrypto: 52.47} (1.2345 ONE -> 52.47 EUR)
 * convertCurrencies({ fiatCrypto: 42.5 }) // {fiat: null, crypto: 1} (42.5 EUR -> 1 ONE)
 */
export const convertCurrencies = (convertibleCurrency: ConvertibleCurrency): ConvertibleCurrencyReturn => {
  const invalidReturn = { fiat: 0, crypto: 0 };

  // Check for valid incoming data
  if (!isConfigured('convertCurrencies') || !isPlainObject(convertibleCurrency)) return invalidReturn;
  const { total, fiat, fiatCrypto, fiatOriginCurrency, fiatDesiredCurrency } = convertibleCurrency;
  const { crypto, cryptoOriginCurrency, cryptoDesiredCurrency } = convertibleCurrency;

  if (!isNumber(fiat) && !(isNumber(crypto) || isNumber(fiatCrypto))) return invalidReturn;

  // Initialize source and target currencies for both fiat and crypto parts
  const { selectedCurrency } = currenciesStore.getState();
  const fiatSourceCurrency = findFiatCurrency(fiatOriginCurrency ?? DEFAULT_FIAT_CURRENCY.code);
  const fiatTargetCurrency = findFiatCurrency(fiatDesiredCurrency ?? selectedCurrency?.code) ?? DEFAULT_FIAT_CURRENCY;
  const cryptoSourceCurrency = findCryptoCurrency(cryptoOriginCurrency ?? DEFAULT_CRYPTO_CURRENCY.code);
  const cryptoTargetCurrency = findCryptoCurrency(cryptoDesiredCurrency) ?? DEFAULT_CRYPTO_CURRENCY;

  // Calculate fiat and crypto part converted from source to target currencies
  // fiatLocalAmount stores converted fiat from fiatOriginCurrency to fiatDesiredCurrency, e.g. 20 EUR -> 39.11 BGN
  // fiatBaseAmount stores converted fiat from fiatOriginCurrency to DEFAULT_FIAT_CURRENCY, e.g. 20 EUR -> 20 EUR, 39.11 BGN -> 20 EUR
  let fiatBaseTotalAmount = null;
  let fiatLocalTotalAmount = null;
  let fiatBaseAmount = null;
  let fiatLocalAmount = null;
  let cryptoAmount = null;
  let fiatBaseCryptoAmount = null;
  let fiatLocalCryptoAmount = null;
  const fiatToOneRate = cryptoSourceCurrency?.rates?.[cryptoTargetCurrency.code] ?? DEFAULT_ONE_RATE;
  const fiatToBaseFiatRate = fiatSourceCurrency?.rates?.[DEFAULT_FIAT_CURRENCY.code] ?? 1;
  const fiatToLocalFiatRate = fiatSourceCurrency?.rates?.[fiatTargetCurrency.code] ?? 1;

  // Convert fiat part of the price
  if (isNumber(fiat)) {
    fiatBaseAmount = roundFiat(mul(fiat, fiatToBaseFiatRate));
    fiatLocalAmount = roundFiat(mul(fiat, fiatToLocalFiatRate));
  }

  // Convert crypto part of the price
  if (isNumber(crypto)) {
    fiatBaseCryptoAmount = roundFiat(mul(crypto, fiatToOneRate));
    fiatLocalCryptoAmount = roundFiat(mul(fiatBaseCryptoAmount, fiatToLocalFiatRate));
    cryptoAmount = crypto;
  } else if (isNumber(fiatCrypto)) {
    fiatBaseCryptoAmount = roundFiat(mul(fiatCrypto, fiatToBaseFiatRate));
    fiatLocalCryptoAmount = roundFiat(mul(fiatBaseCryptoAmount, fiatToLocalFiatRate));
    cryptoAmount = roundCrypto(div(fiatBaseCryptoAmount, fiatToOneRate));
  }

  // Convert the total price, if it is passed
  if (isNumber(total)) {
    fiatBaseTotalAmount = roundFiat(mul(total, fiatToBaseFiatRate));
    fiatLocalTotalAmount = roundFiat(mul(total, fiatToLocalFiatRate));
  }

  return {
    fiatBase: fiatBaseAmount,
    fiatLocal: fiatLocalAmount,
    crypto: cryptoAmount,
    fiatBaseCrypto: fiatBaseCryptoAmount,
    fiatLocalCrypto: fiatLocalCryptoAmount,
    fiatBaseTotal: fiatBaseTotalAmount,
    fiatLocalTotal: fiatLocalTotalAmount,
  };
};

/**
 * Prints full price which has crypto fiat and fiat parts not converted to corresponding currencies\
 * Prints full price which has crypto and fiat parts not converted to corresponding currencies
 *
 * @param fiat fiat not converted from fiat user selected currency - e.g. 40 BGN, 70 BGN
 * @param fiatOriginCurrency the currency of given fiat amount as fiat param, by default is EUR - e.g. BGN, EUR
 * @param fiatDesiredCurrency the currency to which fiat will be converted, by default is EUR - e.g. BGN, EUR
 * @param fiatCrypto fiatCrypto not converted from fiat to crypto - e.g. 42.5 EUR, 76 EUR
 * @param crypto crypto already converted from fiat to crypto - e.g. 4 ONE, 1.4567 ONE
 * @param cryptoOriginCurrency the currency of given fiatCrypto amount as fiatCrypto param, by default is ONE - e.g. ONE, OESP
 * @param cryptoDesiredCurrency the currency to which fiatCrypto will be converted, by default is ONE - e.g. ONE, OESP
 * @param stringify flag to determine the output, if true it will return string if false it will return object
 * @returns {CurrencyPrint} human readable price string with fiat and crypto parts or object with {fiat, crypto} parts
 * @example
 *
 * printPrice(40, 42,5) // 78.25 BGN + 1 ONE
 * printPrice(40, 1) // 78.25 BGN + 1 ONE
 */
export const printPrice = (convertibleCurrency: ConvertibleCurrency): CurrencyPrint => {
  const invalidReturn = '';
  if (!isConfigured('convertCurrencies') || !isPlainObject(convertibleCurrency)) return invalidReturn;

  const {
    fiatDesiredCurrency,
    cryptoDesiredCurrency,
    ignoreEmpty,
    fiatPrecision,
    cryptoPrecision,
    fiatLabelSign = 'symbol',
    stringify = true,
    fiatOnly,
  } = convertibleCurrency;

  const { fiatLocal, crypto, fiatLocalTotal } = convertCurrencies(convertibleCurrency);

  const isFiatOnly = isBoolean(fiatOnly) ? fiatOnly : currenciesStore.getState()?.displayFiatOnly;
  const fiatLabel = `${stringifyToFirstNonZeroDigit(
    (isFiatOnly ? fiatLocalTotal : fiatLocal) ?? 0,
    fiatPrecision ?? 2,
  )} ${fiatSign(fiatDesiredCurrency, fiatLabelSign)}`;

  if (isFiatOnly || !isNumber(crypto) || (!ignoreEmpty && !crypto)) return stringify ? fiatLabel : { fiatLabel };

  const cryptoLabel = `${stringifyToFirstNonZeroDigit(crypto ?? 0, cryptoPrecision ?? 4)} ${cryptoSign(
    cryptoDesiredCurrency,
  )}`;

  if (!isNumber(crypto) || (!ignoreEmpty && !crypto)) return stringify ? fiatLabel : { fiatLabel };
  if (!isNumber(fiatLocal) || (!ignoreEmpty && !fiatLocal)) return stringify ? cryptoLabel : { cryptoLabel };

  return stringify ? `${fiatLabel} + ${cryptoLabel}` : { fiatLabel, cryptoLabel };
};

/**
 * Calculate what are the total price, subtotal price and all promo code discounts inside shopping cart\
 * All deals inside have a pre-calculations through calculateDealPrice
 *
 * @param shoppingCart shoppingCart object for user cart coming from API
 * @see calculateDealPrice method
 */
export const calculateShoppingCart = (shoppingCart: ShoppingCart): ShoppingCart => {
  const result = { ...shoppingCart };
  const { businesses = [] } = result;

  let cartTotalBase: number | null = null,
    cartTotalBaseFiat: number | null = null,
    cartTotalCrypto: number | null = null,
    cartTotalLocal: number | null = null,
    cartTotalLocalFiat: number | null = null,
    discountedCartTotalBase: number | null = null,
    discountedCartTotalBaseFiat: number | null = null,
    discountedCartTotalCrypto: number | null = null,
    discountedCartTotalLocal: number | null = null,
    discountedCartTotalLocalFiat: number | null = null;

  // Iterate over businesses to extract all deals inside every business
  const newBusinesses = businesses.map((business) => {
    const { promoCode } = business;
    const fiatOriginCurrency = business.items?.[0]?.currencyCode ?? DEFAULT_FIAT_CURRENCY.code;
    let discount: number | null = null,
      discountCrypto: number | null = null,
      discountedTotalPrice: number | null = null,
      discountedTotalPriceCrypto: number | null = null,
      discountedTotalPriceFiat: number | null = null,
      discountFiat: number | null = null,
      percentage: number | null = null,
      totalPrice: number | null = null,
      totalPriceCrypto: number | null = null,
      totalPriceFiat: number | null = null;

    // Map deals to calculated prices with applied promo code if it is used
    const newDeals = business.items.map((deal) => {
      // Calculate total prices for business, those fields will be added into every business
      const newDeal = calculateDealPrice(deal);
      const price = newDeal?.discountedPrice ?? newDeal.price;
      const priceFiat = newDeal?.discountedPriceFiat ?? newDeal?.priceFiat;
      const priceCrypto = newDeal?.discountedPriceCrypto ?? newDeal?.priceCrypto;

      // Calculate shopping cart and business totals, those properties will be added into shopping cart and business objects
      if (isNumber(price)) {
        const dealPrice = mul(price, deal.quantity);
        totalPrice = add(totalPrice ?? 0, dealPrice);
        const { fiatBase, fiatLocal } = convertCurrencies({ fiat: dealPrice, fiatOriginCurrency });
        cartTotalBase = add(cartTotalBase ?? 0, fiatBase ?? 0);
        cartTotalLocal = add(cartTotalLocal ?? 0, fiatLocal ?? 0);
      }

      if (isNumber(priceFiat)) {
        const dealFiatPrice = mul(priceFiat, deal.quantity);
        totalPriceFiat = add(totalPriceFiat ?? 0, dealFiatPrice);
        const { fiatBase, fiatLocal } = convertCurrencies({ fiat: dealFiatPrice, fiatOriginCurrency });
        cartTotalBaseFiat = add(cartTotalBaseFiat ?? 0, fiatBase ?? 0);
        cartTotalLocalFiat = add(cartTotalLocalFiat ?? 0, fiatLocal ?? 0);
      }

      if (isNumber(priceCrypto)) {
        const dealCryptoPrice = mul(priceCrypto, deal.quantity);
        totalPriceCrypto = add(totalPriceCrypto ?? 0, dealCryptoPrice);
        const { crypto } = convertCurrencies({ fiatCrypto: dealCryptoPrice, fiatOriginCurrency });
        cartTotalCrypto = add(cartTotalCrypto ?? 0, crypto ?? 0);
      }

      return newDeal;
    });

    // Calculate promo code discount and business discounted price of total minus all promo code discounts
    // Every business can apply only one promo code so that's why we set-up only one discount and discountedTotalPrice groups
    if (promoCode) {
      percentage =
        promoCode.typeId === promoCodeType.percent ? div(promoCode.value, 100) : div(promoCode.value, totalPrice ?? 0);
      // If the user apply code for 200 and the items into cart are 100, he should receive them for free with 100% discount instead of 200% discount
      percentage = percentage > 1 ? 1 : percentage;

      // Calculate discount which is from promo code and discounted shopping cart prices
      if (isNumber(totalPrice)) {
        discount = mul(totalPrice, percentage);
        discountedTotalPrice = sub(totalPrice, discount);
      }

      if (isNumber(totalPriceFiat)) {
        discountFiat = totalPriceFiat ? mul(totalPriceFiat, percentage) : null;
        discountedTotalPriceFiat = totalPriceFiat && discountFiat ? sub(totalPriceFiat, discountFiat) : null;
      }

      if (isNumber(totalPriceCrypto)) {
        discountCrypto = mul(totalPriceCrypto, percentage);
        discountedTotalPriceCrypto = sub(totalPriceCrypto, discountCrypto);
      }
    }

    // Return the new business object with all new properties
    return {
      ...business,
      currencyCode: fiatOriginCurrency,
      items: newDeals.map((el) => calculateDealPriceWithPromoCode(el, percentage)),
      totalPrice: isNumber(totalPrice) ? roundFiat(totalPrice) : null,
      totalPriceCrypto: isNumber(totalPriceCrypto) ? roundCrypto(totalPriceCrypto) : null,
      totalPriceFiat: isNumber(totalPriceFiat) ? roundFiat(totalPriceFiat) : null,
      discountedTotalPrice: isNumber(discountedTotalPrice) ? roundFiat(discountedTotalPrice) : null,
      discountedTotalPriceCrypto: isNumber(discountedTotalPriceCrypto) ? roundCrypto(discountedTotalPriceCrypto) : null,
      discountedTotalPriceFiat: isNumber(discountedTotalPriceFiat) ? roundFiat(discountedTotalPriceFiat) : null,
      promoCode: promoCode && {
        ...promoCode,
        percentage,
        discountedPrice: isNumber(discount) ? roundFiat(discount) : null,
        discountedPriceCrypto: isNumber(discountCrypto) ? roundCrypto(discountCrypto) : null,
        discountedPriceFiat: isNumber(discountFiat) ? roundFiat(discountFiat) : null,
      },
    };
  });

  // Check if at least one business has promo code
  // Shopping cart discounted totals, those properties will be added into shopping cart object
  newBusinesses.some((business) => isNumber(business.discountedTotalPrice)) &&
    newBusinesses.forEach((business) => {
      const fiatOriginCurrency = business?.currencyCode ?? DEFAULT_FIAT_CURRENCY.code;
      const price = business?.discountedTotalPrice ?? business.totalPrice;
      const priceFiat = business?.discountedTotalPriceFiat ?? business?.totalPriceFiat;
      const priceCrypto = business?.discountedTotalPriceCrypto ?? business?.totalPriceCrypto;

      // Calculate shopping cart and business totals, those properties will be added into shopping cart and business objects
      if (isNumber(price)) {
        const { fiatBase, fiatLocal } = convertCurrencies({ fiat: price, fiatOriginCurrency });
        discountedCartTotalBase = add(discountedCartTotalBase ?? 0, fiatBase ?? 0);
        discountedCartTotalLocal = add(discountedCartTotalLocal ?? 0, fiatLocal ?? 0);
      }

      if (isNumber(priceFiat)) {
        const { fiatBase, fiatLocal } = convertCurrencies({ fiat: priceFiat, fiatOriginCurrency });
        discountedCartTotalBaseFiat = add(discountedCartTotalBaseFiat ?? 0, fiatBase ?? 0);
        discountedCartTotalLocalFiat = add(discountedCartTotalLocalFiat ?? 0, fiatLocal ?? 0);
      }

      if (isNumber(priceCrypto)) {
        const { crypto } = convertCurrencies({ fiatCrypto: priceCrypto, fiatOriginCurrency });
        discountedCartTotalCrypto = add(discountedCartTotalCrypto ?? 0, crypto ?? 0);
      }
    });

  // Return the new shopping cart object with all new properties
  return {
    ...result,
    businesses: newBusinesses,
    totalBasePrice: cartTotalBase,
    totalLocalPrice: cartTotalLocal,
    totalPriceCrypto: cartTotalCrypto,
    totalBasePriceFiat: cartTotalBaseFiat,
    totalLocalPriceFiat: cartTotalLocalFiat,
    discountedTotalBasePrice: discountedCartTotalBase,
    discountedTotalLocalPrice: discountedCartTotalLocal,
    discountedTotalPriceCrypto: discountedCartTotalCrypto,
    discountedTotalBasePriceFiat: discountedCartTotalBaseFiat,
    discountedTotalLocalPriceFiat: discountedCartTotalLocalFiat,
  };
};

/**
 * Calculate if the user has enough crypto amount to buy specific deal\
 * Every order can be paid only with crypto wallets
 *
 * @param shoppingCart shoppingCart object for user cart coming from API
 * @param coinWalletBalance coin wallet ballance, which can be used only if the user is from not crypto restricted country
 * @param oespWalletBalance oesp wallet ballance, which can be used if the user is from crypto restricted country or not
 * @param deal deal object for specific deal with already calculated prices with calculateDealPrice method
 * @return {string | null} if there is not enough balance it will return error code, otherwise returns null
 * @see calculateDealPrice method
 */
export const hasCryptoAmountForCart = ({
  shoppingCart,
  coinWalletBalance,
  oespWalletBalance,
  deal,
}: ShoppingCartCheckAmount): string | null => {
  let priceFiatCrypto = shoppingCart?.discountedTotalPriceCrypto ?? shoppingCart?.totalPriceCrypto ?? 0;

  if (deal) {
    const crypto = deal?.discountedPriceCrypto ?? deal?.priceCrypto ?? 0;
    const quantity = deal?.quantity ?? 1;
    priceFiatCrypto = add(priceFiatCrypto, mul(crypto, quantity));
  }
  const priceCrypto = roundCrypto(convertCurrencies({ fiatCrypto: priceFiatCrypto })?.crypto ?? 0);

  const noOesp = oespWalletBalance < priceCrypto;
  const noCrypto = !getLocationsState()?.geoLocation?.isCryptoRestricted
    ? coinWalletBalance < priceCrypto && noOesp
    : noOesp;
  return noCrypto ? 'yourBalanceNotEnough' : null;
};

/**
 * Change the user selected currency to which all prices are converted and displayed
 *
 * @param currency currency object or currency code to which you want to switch
 * @example
 *
 * changeUserCurrency(CurrencyObject)
 * changeUserCurrency('BGN')
 */
export const changeUserCurrency = (currency: Currency | string): void => {
  if (!isConfigured('changeUserCurrency')) return;

  const matchedCurrency = findFiatCurrency(isString(currency) ? currency : currency?.code) ?? DEFAULT_FIAT_CURRENCY;
  setStorageValue(CURRENCY_KEY, matchedCurrency?.code, config.storage);
  currenciesStore.setState((prev) => ({ ...prev, selectedCurrency: matchedCurrency }));
};

/**
 * Change display currency to be fiatOnly or fiat + crypto
 *
 * @param fiatOnly boolean to turn on and off fiat only display, false for turn off
 * @example
 *
 * setDisplayFiatOnly(true) // 50 EUR
 * setDisplayFiatOnly(false)  // 1 ONE + 7.5 EUR
 */
export const setDisplayFiatOnly = (fiatOnly: boolean): void =>
  currenciesStore.setState((prev) => ({ ...prev, displayFiatOnly: fiatOnly }));

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @returns {CurrenciesStore} object with data for currencies
 * @see CurrenciesStore
 * @example
 *
 * useCurrencies(state => state.selectedCurrency) // this component will re-render only when there is change inside selectedCurrency
 * useCurrencies(state => state.currencyRates) // this component will re-render only when there is change inside currencyRates
 */
export const useCurrencies = (callback: SelectorFunction<CurrenciesStore>): CurrenciesStore | null => {
  if (!isConfigured('useCurrencies')) return null;

  return useStore(currenciesStore, callback);
};
